<template>
  <div v-cloak>
    <div v-show="marketingConsent">
      <div class="pb-[56.25%] relative">
        <div class="absolute inset-0">
          <div v-if="!active" class="flex flex-col justify-center items-center h-full">
            <div class="border-2 border-white flex items-center py-5 px-10 mb-10 rounded-10 rounded-bl-none">
              <span class="inline-block animate-pulse rounded-full bg-red-400 square-13"></span>
              <span class="ml-6 leading-18 font-mono font-bold uppercase">Live</span>
            </div>
            <div>Venter på at live stream starter</div>
          </div>
          <div :id="playerId" ref="jwPlayerInstanceRef"></div>
        </div>
      </div>
    </div>
    <LazyConsentPrompt v-show="!marketingConsent" />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
const props = defineProps({
  playerId: {
    type: String,
    required: false,
  },
  setupOverride: {
    type: Object,
    required: false,
  },
  media: {
    type: Object,
    required: true,
  },
  related: {
    type: Boolean,
    default: false,
  }
});

const { public: config } = useRuntimeConfig();

const playerId = props.playerId || config.jwPlayer.playerIds.default;

const marketingConsent = useAllerMarketingConsent();

const { data: channelStatus, refresh: refreshChannelStatus }: { data: any; refresh: any } = await useFetch(`https://cdn.jwplayer.com/live/channels/${props.media.channel_id}.json`);
const active = computed(() => channelStatus.value.status !== 'idle');

const currentEventId = ref();

const jwPlayerInstance = ref<any>(null);

const loadScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.id = `${playerId}-script`;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.jwplayer.com/libraries/${playerId}.js`;
    script.onerror = (err) => {
      reject(err);
    };
    script.onload = () => {
      return resolve(true);
    };
    document.body.appendChild(script);
  });
};

/**
 * Setup a basic JWplayer with the eventId as source media.
 * @param eventId
 */
const configurePlayer = async (eventId: string) => {
  const { data: playlist }: { data: any, refresh: any } = await useFetch(() => `https://cdn.jwplayer.com/v2/media/${eventId}`);
  // If we don't have a playlist continue the interval polling.
  if (!playlist.value) {
    return;
  }

  //@ts-ignore.
  jwPlayerInstance.value = jwplayer(playerId).setup({
    playlist: playlist.value.playlist,
    repeat: false,
  });

  jwPlayerInstance.value.play();
}

/**
 * Refresh the channel status and act accordingly.
 */
const refresh = () => {
  refreshChannelStatus;

  // If status is active, we do not need to poll anymore.
  if (channelStatus.value.status === 'active') {
    const eventId = channelStatus.value['current_event'];

    // Check if we have seen this eventId before.
    if (currentEventId.value === eventId) {
      // The eventId returned by the API was not a *new* event id.
      // Ignore it and continue polling until we see a new id.
      setTimeout(() => { refresh() }, 10000);
    }
    currentEventId.value = eventId;
    const waitForJWPlayer = setInterval(() => {
      // @ts-ignore.
      if (typeof jwplayer === 'undefined') { return; }
      configurePlayer(eventId);
      clearInterval(waitForJWPlayer);
    }, 100);
  }
  else {
    // Poll for a status on the channel again.
    setTimeout(() => { refresh() }, 10000);
  }
};

onMounted(async () => {
  await loadScript();
  refresh();
});
</script>
